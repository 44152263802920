/* eslint no-console: "off" */
import {
  SET_AUTH_ERROR,
  CLEAR_AUTH,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_IN_PROGRESS,
  FORGOT_PASSWORD_ERROR,
  SIGNIN_PASSWORD_FAIL,
  CLEAR_PASSWORDLESS_AUTH,
  USER_NOT_FOUND,
  FAIL_PASSWORDLESS_AUTH,
  CLOSE_LOGIN_SLIDE,
  CART_CLEAR,
  TOKEN_EXPIRED,
  FIRST_LOGIN_AFTER_COMPLETE_QUIZ,
  SET_PATH_BEFORE_LOGIN,
  SET_LOGOUT_USER_ATTEMPT,
  CLEAR_LOGOUT_USER_ATTEMPT
} from '../constants/actionTypes'
import { MAGIC_TEMPORARY_QUIZ_ANSWERS } from 'constants/user/user-trait'
import { Auth as AWSAuth } from 'aws-amplify'
import { SENT_TEMPORARY_PASSWORD } from 'constants/messages'
import provenApi from '../services/proven-api'
import { checkUserExistsUrl, SHOPIFY_WEBSITE_DOMAIN } from '../constants/endpoints'
import {
  getOrderHistory,
  getUserDetails,
  getUserSubscriptions,
  redeemGiftCard
} from './account.actions'
import { pathOr } from 'ramda'
import { isThereRecentSignUpSelector, questionsSelectorGeneric } from '../utils/selectors'
import { patchUserQuiz } from './quiz.actions'
import { history } from '../store/configureStore'
import _ from 'lodash'
import {
  getRedirectionByEventNameAndPath,
  getRedirectionByEventNameOrPath,
  getMLEventFromParms
} from '../utils/helpers/userTypeAccessHelper'
import {
  ON_AFTER_QUIZ_EVENT_BY_PRODUCT_KEYWORD,
  onAfterAuthentication,
  onAfterAuthenticationWithGiftCard
} from '../constants/user/event-name'
import { getQuestionnaireInfo } from '../utils/questionnaire-configurator'
import { KEYWORD_SKIN } from '../constants/funnels'

export const setAuthError = (error = '') => {
  const timestamp = Date.now()
  return {
    type: SET_AUTH_ERROR,
    error,
    timestamp
  }
}

export const signoutUser = () => () => AWSAuth.signOut()

//this action is added only for handling logic before user confirm that he/she wants to logout (for instance clicking on logout on thank-you page)
export const setLogoutUserAttempt = () => {
  return { type: SET_LOGOUT_USER_ATTEMPT }
}
export const clearLogoutUserAttempt = () => {
  return { type: CLEAR_LOGOUT_USER_ATTEMPT }
}

export const clearAuth = () => ({
  type: CLEAR_AUTH
})

export const clearPasswordlessAuth = () => ({
  type: CLEAR_PASSWORDLESS_AUTH
})

export const forgotPassword = email => async dispatch => {
  dispatch({ type: FORGOT_PASSWORD_IN_PROGRESS })
  try {
    await AWSAuth.forgotPassword(email)
    dispatch({ type: FORGOT_PASSWORD_SUCCESS })
  } catch (err) {
    dispatch(forgotPasswordError(err.message))
    throw new Error(err.message)
  }
}

export const passwordlessSignIn =
  ({ email, iterableEmailCampaignId, mostRecentAnswers }) =>
  async dispatch => {
    dispatch({ type: FORGOT_PASSWORD_IN_PROGRESS })
    try {
      await provenApi.post('login', { email, answers: mostRecentAnswers, iterableEmailCampaignId })
      dispatch({ type: FORGOT_PASSWORD_SUCCESS })
    } catch (err) {
      dispatch(forgotPasswordError(err.message))
      throw new Error(err.message)
    }
  }

export const forgotPasswordError = error => {
  const timestamp = Date.now()
  return {
    type: FORGOT_PASSWORD_ERROR,
    error,
    timestamp
  }
}

export const failPasswordlessAuth = (username, isExpired) => ({
  type: FAIL_PASSWORDLESS_AUTH,
  data: {
    error: isExpired ? TOKEN_EXPIRED : USER_NOT_FOUND,
    username,
    message: isExpired ? 'Token is Expired' : 'Invalid email, Please take the Skin Quiz'
  }
})

export const checkUserExists = (username, message) => async dispatch => {
  try {
    const { data } = await provenApi.get(checkUserExistsUrl, {
      params: { email: encodeURI(username) }
    })

    if (data.status === 'RESET_REQUIRED') {
      dispatch({
        type: SIGNIN_PASSWORD_FAIL,
        error: 'PASSWORD_RESET_REQUIRED',
        username,
        message: ''
      })
    } else if (data.status === 'FORCE_CHANGE_PASSWORD') {
      dispatch({
        type: SIGNIN_PASSWORD_FAIL,
        error: 'FORCE_CHANGE_PASSWORD',
        username,
        message: SENT_TEMPORARY_PASSWORD
      })
    } else {
      dispatch({
        type: SIGNIN_PASSWORD_FAIL,
        error: 'SIGNIN_PASSWORD_FAIL',
        username,
        message
      })
    }
    return data
  } catch (error) {
    dispatch({
      type: SIGNIN_PASSWORD_FAIL,
      error: 'USER_NOT_FOUND',
      username,
      message: ''
    })
  }
}

const onAuthSetup =
  ({ questionnaireId, answers, identify, doNotUpdateAccountInfo, doNotClearQuizAnswers }) =>
  async (dispatch, getState) => {
    try {
      await dispatch(redeemGiftCard())
      // get necessary data from BE

      const isThereRecentSignUp = isThereRecentSignUpSelector(getState())
      const { accountInfo } = await dispatch(
        getUserDetails(identify, doNotUpdateAccountInfo || isThereRecentSignUp)
      )
      await dispatch(getUserSubscriptions())
      await dispatch(getOrderHistory())

      // check if quiz needs to be patched
      const state = getState()
      const questions = questionsSelectorGeneric()(state)
      if (!answers) {
        answers = getQuestionnaireInfo(questionnaireId).answersSelector(state)
      }
      const hasCompleted25Percent =
        answers && questions && Object.keys(answers).length / questions.length > 0.25
      if (hasCompleted25Percent && !isThereRecentSignUp && !doNotClearQuizAnswers) {
        await dispatch(
          patchUserQuiz(answers, questionnaireId, doNotClearQuizAnswers || isThereRecentSignUp)
        )
      }
      return accountInfo
    } catch (e) {
      console.error(`onAuthSetup error: `, e)
    }
  }

const getOnAuthTargetRoute = (state, eventName, pathFromWhereRedirect) => {
  const subscriptions = pathOr([], ['account', 'subscriptions'], state)
  const activeSubscriptions = (subscriptions || []).filter(subs =>
    ['active', 'future', 'in_trial'].includes(subs.status)
  )
  const inactiveSubscriptions = (subscriptions || []).filter(
    subs => subs.status === 'cancelled' || subs.status === 'paused'
  )
  const orders = pathOr([], ['account', 'orders'], state)

  const completedSkinQuiz = pathOr(false, ['account', 'completedSkinQuiz'], state)
  const completedEyeQuiz = pathOr(false, ['account', 'completedEyeQuiz'], state)
  const completedSerumQuiz = pathOr(false, ['account', 'completedSerumQuiz'], state)

  // TODO pass funnel to make redirections be based on funnel for all subscribers onAuthentication: `/account/shop/${funnel}`
  const stateDataForDeterminingRedirection = {
    activeSubscriptions,
    inactiveSubscriptions,
    orders,
    completedSkinQuiz,
    completedEyeQuiz,
    completedSerumQuiz
  }
  let route
  if (pathFromWhereRedirect)
    route = getRedirectionByEventNameAndPath(
      eventName,
      pathFromWhereRedirect,
      stateDataForDeterminingRedirection
    )
  if (!route) route = getRedirectionByEventNameOrPath(eventName, stateDataForDeterminingRedirection)
  return route
}

export const getOnAuthTargetRouteAfterQuiz = (quizType, state) => {
  return getOnAuthTargetRoute(state, ON_AFTER_QUIZ_EVENT_BY_PRODUCT_KEYWORD[quizType])
}

const onAuthRedirect =
  ({ isNewbie, afterQuiz, questionnaireId }) =>
  async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      try {
        console.log('onAuthRedirect', isNewbie)
        // get updated details from redux
        const state = getState()
        const userInfo = pathOr(null, ['account', 'info'], state)
        const giftCard = pathOr(null, ['checkout', 'giftCard'], state)
        const previousLocation = _.get(history.location, 'state.from', '') //TODO on ENG-2039 unify previousLocation and pathBeforeLogin
        const pathBeforeLogin = pathOr(null, ['auth', 'pathBeforeLogin'], state) //we need it for the social login, since amplify has a redirection in the middle, and we lose history.location state
        const subscriptions = pathOr([], ['account', 'subscriptions'], state)
        const activeSubscriptions = (subscriptions || []).filter(subs =>
          ['active', 'future', 'in_trial'].includes(subs.status)
        )
        const inactiveSubscriptions = (subscriptions || []).filter(
          subs => subs.status === 'cancelled' || subs.status === 'paused'
        )
        const orders = pathOr([], ['account', 'orders'], state)
        const completedSkinQuiz = pathOr(false, ['account', 'completedSkinQuiz'], state)
        const completedEyeQuiz = pathOr(false, ['account', 'completedEyeQuiz'], state)
        const completedSerumQuiz = pathOr(false, ['account', 'completedSerumQuiz'], state)
        dispatch(setPathBeforeLogin(null))
        // default search params
        let searchFromLocation = _.get(history.location, 'search', '')

        // default post-auth route, at the end of quiz or after authentication
        const eventName = !afterQuiz
          ? giftCard
            ? onAfterAuthenticationWithGiftCard
            : onAfterAuthentication
          : ON_AFTER_QUIZ_EVENT_BY_PRODUCT_KEYWORD[afterQuiz || KEYWORD_SKIN]
        let redirectUrl = getOnAuthTargetRoute(state, eventName, pathBeforeLogin)

        if (!afterQuiz && previousLocation.indexOf('account') === 1) {
          // if user should be redirected back to RUD
          searchFromLocation = _.get(history.location, 'state.fromSearch', '')

          redirectUrl = previousLocation
        } else if (userInfo.isAdmin) {
          redirectUrl = '/admin/dashboard'
        }

        if (questionnaireId) {
          redirectUrl = getQuestionnaireInfo(questionnaireId).congratsPageUri
        }
        const params = new URLSearchParams(window.location.search)
        const token = params.get('token')
        const email = params.get('email')

        // Do not display token and email in URL for security and privacy
        if (token && email) {
          params.delete('token')
          params.delete('email')
          searchFromLocation = `?${params}`
          const magicLinkLoginEvent = getMLEventFromParms(params)
          if (magicLinkLoginEvent !== undefined) {
            redirectUrl = getRedirectionByEventNameOrPath(magicLinkLoginEvent, {
              activeSubscriptions,
              inactiveSubscriptions,
              orders,
              completedSkinQuiz,
              completedEyeQuiz,
              completedSerumQuiz
            })
          }
        }
        const redirectInfo = {
          pathname: redirectUrl,
          search: searchFromLocation,
          state: { isNewbie }
        }
        //This means that when user registers for the first time after quiz, we only return the redirect info to React in order to show a Summary page before redirecting to Congratulations page.
        const recentSignUp = isThereRecentSignUpSelector(state)
        if ((isNewbie && afterQuiz) || recentSignUp) {
          resolve(redirectInfo)
          return
        }
        history.push(redirectInfo)
        resolve(redirectInfo)
      } catch (err) {
        reject(err)
      }
    })
  }

export const onAuthSetupAndRedirect =
  ({
    questionnaireId = '',
    isNewbie = false,
    afterQuiz,
    identify = false,
    doNotUpdateAccountInfo = false,
    doNotClearQuizAnswers = false
  } = {}) =>
  async dispatch => {
      return new Promise(async (resolve, reject) => { //eslint-disable-line
      try {
        console.log('onAuthSetupAndRedirect', isNewbie)
        const cognitoUser = await AWSAuth.currentAuthenticatedUser()
        const userAttributes = await AWSAuth.userAttributes(cognitoUser)
        const temporaryQuizAnswersAttribute = userAttributes.find(
          userAttribute => userAttribute.Name === MAGIC_TEMPORARY_QUIZ_ANSWERS
        )
        let answers
        if (temporaryQuizAnswersAttribute) {
          answers = JSON.parse(temporaryQuizAnswersAttribute.Value)
          questionnaireId = answers.id
          dispatch(setFirstLoginAfterCompleteQuiz(true))
          // Delete user attributes for quiz and answers
          await AWSAuth.deleteUserAttributes(cognitoUser, [MAGIC_TEMPORARY_QUIZ_ANSWERS])
        }
        const accountInfo = await dispatch(
          onAuthSetup({
            questionnaireId,
            answers,
            identify,
            doNotUpdateAccountInfo,
            doNotClearQuizAnswers
          })
        )
        if (accountInfo.shopifyId) {
          let shopifyUrl = new URL(`https://${SHOPIFY_WEBSITE_DOMAIN}/auth/user/passwordless`)
          shopifyUrl.searchParams.set('token', accountInfo.magicLinkToken)
          shopifyUrl.searchParams.set('email', accountInfo.email)
          return void (location.href = shopifyUrl)
        }
        setTimeout(async () => {
          // timeout isn't great but we need some time for data to be updated to get a proper route
          const afterAuthRedirectUrlInfo = await dispatch(
            onAuthRedirect({ isNewbie, afterQuiz, questionnaireId })
          )
          dispatch({ type: CLOSE_LOGIN_SLIDE })
          dispatch({ type: CART_CLEAR })
          resolve({ afterAuthRedirectUrlInfo, accountInfo })
        }, 500)
      } catch (err) {
        reject(err)
      }
    })
  }

export const setPathBeforeLogin = path => {
  return {
    type: SET_PATH_BEFORE_LOGIN,
    path
  }
}

export const setFirstLoginAfterCompleteQuiz = isFirstLoginAfterCompleteQuiz => {
  return {
    type: FIRST_LOGIN_AFTER_COMPLETE_QUIZ,
    data: {
      isFirstLoginAfterCompleteQuiz
    }
  }
}
